<template>
  <!-- <div class="page">
    <div class="account-page">
      <div class="account-center">
        <div class="center-box account-information">
          <div class="account-data">
                  <div class="reward-data-modal">
                    <div class="mask" v-if="showModalSucc" @click="confirmShow"></div>
                    <div class="pop" v-if="showModalSucc">
                      <div class="data-box">
                        <img @click="confirmShow" class="close-btn" src="@/assets/images/transaction/close-icon.png" alt="">
                        <div class="reward-box succes-box">
                          <div class="reward-data">
                            <img class="cg-icon" :src="require('@/assets/images/transaction/cg-icon'+styleimg+'.png')" alt="">
                            <p class="title">{{$t('accountCenter.depositSucc')}}</p>
                            <div class="succes-data" :class="styleState">
                              <div class="succes-list">
                                <p class="p1">{{$t('accountCenter.depositTite2')}}</p>
                                <p class="p2">{{amount}} {{currency}}</p>
                              </div>
                              <div class="succes-list">
                                <p class="p1">{{$t('accountCenter.depositTime')}}</p>
                                <p class="p2">{{depositTime}}</p>
                              </div>
                              <button @click="confirmShow">{{$t('accountCenter.confirm')}}</button>
                            </div>
                          </div>               
                        </div>                     
                      </div>     
                    </div>          
                  </div>

                  <div class="reward-data-modal">
                    <div class="mask" v-if="showModalFail" @click="confirmShow"></div>
                    <div class="pop" v-if="showModalFail">
                      <div class="data-box">
                        <img @click="confirmShow" class="close-btn" src="@/assets/images/transaction/close-icon.png" alt="">
                        <div class="reward-box succes-box">
                          <div class="reward-data">
                            <img class="cg-icon" :src="require('@/assets/images/transaction/sb-icon'+styleimg+'.png')" alt="">
                            <p class="title">{{$t('accountCenter.depositFail')}}</p>
                            <div class="succes-data" :class="styleState">
                              <button @click="confirmShow">{{$t('accountCenter.confirm')}}</button>
                            </div>
                          </div>               
                        </div>                     
                      </div>     
                    </div>          
                  </div>
                </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="payment" v-if="showModalSucc">
        <div class="top">
        <img class="mg" src="@/assets/images/appH5/sucss.svg" alt="">
        <p class="text">恭喜您，轉帳成功</p>
        </div>
        <div class="center" >
            <div class="list" v-for="(item,index) in dataListSucc" :key="index">
                <p class="p1">{{ item.name }}</p>
                <p class="p2">{{ item.money }}</p>
            </div>
            <div class=""></div>
        </div>
    </div>
    <div class="payment" v-if="showModalFail">
        <div class="top">
        <img class="mg" src="@/assets/images/appH5/fail.svg" alt="">
        <p class="text">入金失败</p>
        </div>
        <div class="center" >
            <div class="list" v-for="(item,index) in dataListFail" :key="index">
                <p class="p1">{{ item.name }}</p>
                <p class="p2">{{ item.money }}</p>
            </div>
            <div class=""></div>
        </div>
    </div>



</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import profit from "@/util/profitsdk";

export default {
  name: "DepositCallback",
  components: {
    Header,
    LeftMenu,
    TransactionNav
  },
  data() {
    return {
      isClient: profit.isClient,
      loginState:2,
      styleState:'',
      styleimg:'',
      showModalSucc:false,
      showModalFail:false,
      transId:'',
      status:0,
      amount:'',
      currency:'',
      payTypeName:'',
      depositTime:'',
      dataListSucc:[],
    dataListFail:[
    {name:'訂單狀態',money:'轉帳失败'},
    ]
    }
  },
  created() {
    console.log(this.$route.query);
    this.transId = this.$route.query.transId;
    this.status= this.$route.query.status;
    this.amount= this.$route.query.amount;
    this.currency= this.$route.query.currency;
    this.payTypeName= this.$route.query.payTypeName;
    var dataTime = this.$route.query.successTime;
    this.depositTime = this.timestampToTime(parseInt(dataTime));
    this.dataListSucc=[
            {name:'轉帳金額',money:'$'+this.amount},
            {name:'轉帳方式',money:this.payTypeName},
            {name:'訂單號',money:this.transId},
            {name:'訂單狀態',money:'轉帳成功'},
            {name:'轉帳日期',money: this.depositTime},

    ];

    if(this.status == 0){
      this.showModalFail = true;
    }else{
      this.showModalSucc = true;
    }
    // 根据马甲包ui颜色改变页面ui颜色
    const appId = this.$route.query.appId;
      switch(appId) {
          case "com.exchange6.app":
          case "com.fnsadw.ios":
          case "com.cmtrading.app.droid":
          case "com.mobile.trade.finance.emns":
          case "com.finance.etoo.invest": 
          case "com.cmtrading.axbk.ios":  
              console.log("blue-submit-deposit");
              this.styleState = "blue-succes-data";
              this.styleimg = "-blue";
              break;
          case "com.forex.market.investasikan":
          case "com.forex.app03.ios":
          case "com.get.money.investapp.gold":
              console.log("orange-submit-deposit");
              this.styleState = "orange-succes-data";
              this.styleimg = "-orange";
              break;
          case "com.stock.market.cm.tw":
              console.log("green-submit-deposit");
              this.styleState = "green-succes-data";
              this.styleimg = "-green";
              break;    
          default:
              this.styleState = " ";
              this.styleimg = "";
      } 
  },
  methods: {
     getDate(strDate) {
        var st = strDate;
        var a = st.split(" ");
        var b = a[0].split("-");
        var c = a[1].split(":");
        var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
        return date;
      },
// 支付成功确认关闭按钮
    confirmShow(){
      this.showModalSucc = false;
      this.showModalFail = false;
      if (profit.isClient){
        profit.deposit({
          success(){},
          fail(){}
        })
      }else {
        // this.$router.push({
        //   path: 'Deposit'
        // });
        window.opener = null;
        window.close();
      }
    },
    // 将时间戳转换为日期格式
    timestampToTime(timestamp) {
      console.log(11111)
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate(): date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0'+ date.getHours(): date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes(): date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds(): date.getSeconds());
        return Y+M+D+h+m+s;
    },
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
