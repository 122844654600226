import bridge from "dsbridge";

var reg = /Profit\/\d\.\d\.\d/;
var result = navigator.userAgent.match(reg);
var isClient = result != null;
var clientVersion = isClient ?  result[0].split("/")[1] : "1.0.0";
//----兼容H5 APP
var isH5App = false;
if (!isClient){
    // document.domain = window.location.host.substring(window.location.host.indexOf(".") + 1);
    // clientVersion = getCookie("CLIENT-VERSION");
    // result = clientVersion.match(reg);
    // isClient = isH5App = result != null;
    // clientVersion = isClient ?  result[0].split("/")[1] : "1.0.0";
}

let isIOS = navigator.userAgent.match(/(iPhone|iPod|ios|iPad)/i);
let isAndroid = navigator.userAgent.match(/Android/i);

//----
var profit = {
    default: this,
    //是否是APP客户端
    isClient:isClient,
    //是否是H5 APP客户端
    isH5App: isH5App,
    //是否是iOS APP客户端
    isIOSApp: isIOS,
    //是否是iOS APP客户端
    isAndroidApp: isAndroid,
    //APP客户端版本
    clientVersion:clientVersion,
    //检查用户是否登录，如果已登录回调success,否则回调fail
    checkSession:function(callback) {
        bridge.call("checkSession","checkSession",function (res) {
            parseResult(callback,res);
        });
    },
    //用户登录接口，登录成功回调success,否则回调fail
    login:function (callback) {
        bridge.call("login","login",function (res) {
            parseResult(callback,res);
        });
    },
    //用户注册接口，登录成功回调success,否则回调fail
    register:function(callback) {
        bridge.call("register","register",function (res) {
            parseResult(callback,res);
            // var result = JSON.parse(res);
            // if (result.success){
            //     refreshToken(result.value.userToken, {
            //         success(data){
            //             callback.success(result.value);
            //         },
            //         fail(data){
            //             callback.fail(data);
            //         }
            //     });
            // } else {
            //     callback.fail(result.message);
            // }
        });
    },
    //获取用户信息接口，用户如果已登录回调success,否则回调fail
    getUserInfo:function(callback) {
        bridge.call("getUserInfo","getUserInfo",function (res) {
            parseResult(callback,res);
        });
    },
    //调用本地分享接口，分享成功回调success，否则回调fail
    shareTo:function(json,callback) {
        bridge.call("shareTo",json,function (res) {
            parseResult(callback,res);
        });
    },
    //行情推送消息
    onTickChange:function (callback) {
        bridge.register("onTickChange",function (res) {
            callback(JSON.parse(res));
        });
    },
    //入金，打开入金界面回调success，否则回调fail
    deposit:function (callback) {
        bridge.call("deposit","deposit",function (res) {
            parseResult(callback,res);
        });
    },
    //客服，打开联系客服界面回调success，否则回调fail
    contactService:function (callback) {
        bridge.call("contactService","contactService",function (res) {
            parseResult(callback,res);
        });
    },
    //打开品种行情详情，打开界面回调success，否则回调fail
    openProduct:function (callback) {
        bridge.call("openProduct","openProduct",function (res) {
            parseResult(callback,res);
        });
    },
    //事件上报
    appEvent:function (json,callback) {
        bridge.call("appEvent",json,function (res) {
            parseResult(callback,res);
        });
    },
    //注册入金提交事件上报
    depositeApply:function (json,callback) {
        bridge.call("depositeApply",json,function (res) {
            parseResult(callback,res);
        });
    },
    //app内打开H5页面
    goPage:function (json,callback) {
        bridge.call("goPage",json,function (res) {
            parseResult(callback,res);
        });
    },
    //进入引导页
    gotoGuide:function (json,callback) {
        bridge.call("gotoGuide",json,function (res) {
            parseResult(callback,res);
        });
    },
    //进入身份认证
    gotoIdentity:function (json,callback) {
        bridge.call("gotoIdentity",json,function (res) {
            parseResult(callback,res);
        });
    },
};


function parseResult(callback,res) {
    var result = JSON.parse(res);
    if (result.success){
        callback.success(result.value);
    } else {
        callback.fail(result.message)
    }
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default profit;

